import { Component, Input, OnInit } from '@angular/core';
import { UsuarioService } from '../../services/usuario.service';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { Usuario } from '../../../model/usuario.model';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-usuario-form',
  templateUrl: './usuario-form.page.html',
  styleUrls: ['./usuario-form.page.scss'],
})
export class UsuarioFormPage implements OnInit {

  @Input() usuario: Usuario = {};
  constructor( private usuarioService: UsuarioService,
               private modalCtrl: ModalController,
               private ui: UiSereviceService) { }

  ngOnInit() {
  }

  async setUsaurio(){
    const user = await this.usuarioService.setUsuarios(this.usuario);
    if (!user.ok){
      this.ui.alertError(user.error);
      return;
    }
    this.modalCtrl.dismiss(user.usuario);
  }

  async updateUsuario(){
    const user = await this.usuarioService.updateUsuarios(this.usuario);
    if (!user.ok){
      this.ui.alertError(user.error);
      return;
    }
    this.modalCtrl.dismiss(user.usuario);
  }

}
