import { CultivoSelectComponent } from './../../select/cultivo-select/cultivo-select.component';
import { Component, Input, OnInit } from '@angular/core';
import { Campanha, Fincas } from '../../../model/campanha.model';
import { ModalController, AlertController } from '@ionic/angular';
import { FincaSelectComponent } from '../../select/finca-select/finca-select.component';
import { CampanhaService } from '../../services/campanha.service';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { FincaService } from '../../services/finca.service';
import { Finca } from '../../../model/finca.model';


@Component({
  selector: 'app-campanha-form',
  templateUrl: './campanha-form.page.html',
  styleUrls: ['./campanha-form.page.scss'],
})
export class CampanhaFormPage implements OnInit {

  
  @Input() campanha: Campanha = {};
  @Input() edit: boolean = false;
  constructor( private modalCtrl: ModalController,
               private campanhaService: CampanhaService,
               private ui: UiSereviceService,
               private alertCtrl: AlertController,
               private fincaSerice: FincaService) {}

  async ngOnInit() {
  }
  

  async cultivoSelect(){
    const modal = await this.modalCtrl.create({
      component: CultivoSelectComponent,
      cssClass: 'cultivoSelect-modal'
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data){
      this.campanha.cultivo = data;
    }
  }  

  enviar(){
      this.modalCtrl.dismiss(this.campanha);
  }
  

  async finalizaCampanha(){
    const ok = await this.ui.presentConfirm('');
    if (!ok){
      return;
    }
    this.campanha.fechaFin = new Date();
    this.campanha.activa = false;
    // this.campanha.cultivo = this.campanha.cultivo._id;
    console.log(this.campanha);
    const finalizada = await this.campanhaService.updateCampanha(this.campanha);
    if (!finalizada.ok){
      this.ui.alertError('Error al finalizar la campanña');
      return;
    }
    this.ui.presentToast('Campala finalizada.');
    this.modalCtrl.dismiss(true);
  }

}
