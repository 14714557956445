import { Component, Input, OnInit } from '@angular/core';
import { Cliente } from '../../../model/clientes.model';
import { ModalController } from '@ionic/angular';
import { ClientesService } from '../../services/clientes.service';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { FormapagoFormPage } from '../formapago-form/formapago-form.page';
import { ProveedorService } from '../../services/proveedor.service';
import { FormaPago } from '../../../model/formaPago.model';

@Component({
  selector: 'app-cliente-form',
  templateUrl: './cliente-form.page.html',
  styleUrls: ['./cliente-form.page.scss'],
})
export class ClienteFormPage implements OnInit {

  @Input() cliente: Cliente = {};
  formasPago: FormaPago[] = [];
  constructor( private modalCtrl: ModalController,
               private clientesService: ClientesService,
               private proveedorService: ProveedorService,
               private ui: UiSereviceService) { }

  ngOnInit() {
    /* if (!this.cliente.direccion){
      this.cliente.direccion = '';
      this.cliente.localidad = '';
      this.cliente.cp = 0;
      this.cliente.telefono = 0;
    }
    */
    this.cargarFormaPago();
  }


  async cargarFormaPago(){
    const fp = await this.proveedorService.getFormasPago();
    if (!fp.ok){
      this.ui.alertError(fp.error);
      return;
    }
    this.formasPago = fp.formasPago;
  }


  async newFormaPago(){
    const modal = await this.modalCtrl.create({
      component: FormapagoFormPage,
      cssClass: 'formaPago-modal'
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data){
      await this.cargarFormaPago();
    }
  }

  async enviar(){
    let cli;
    if (!this.cliente._id){
    cli = await this.clientesService.setCliente(this.cliente);
    } else {
      cli = await this.clientesService.updateClient(this.cliente);
    }
    console.log(cli);
    if (!cli.ok){
      this.ui.alertError(cli.error);
      return;
    }
    this.modalCtrl.dismiss(true);
  }

}
