import { Component } from '@angular/core';
import { ImpuestoService } from '../../services/impuesto.service';
import { Impuesto } from '../../../model/impuesto.model';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-impuesto-form',
  templateUrl: './impuesto-form.page.html',
  styleUrls: ['./impuesto-form.page.scss'],
})
export class ImpuestoFormPage {

  impuesto: Impuesto = {};

  constructor( private impuestoService: ImpuestoService,
               private ui: UiSereviceService,
               private modalCtrl: ModalController) { }

  async enviar(){
    const imp = await this.impuestoService.setImpuesto(this.impuesto);
    if (!imp.ok){
      this.ui.alertError(imp.error);
      return;
    }
    this.modalCtrl.dismiss(true);
  }

}
