import { Component } from '@angular/core';
import { FormaPago } from '../../../model/formaPago.model';
import { ModalController } from '@ionic/angular';
import { ProveedorService } from '../../services/proveedor.service';
import { UiSereviceService } from '../../services/ui-serevice.service';

@Component({
  selector: 'app-formapago-form',
  templateUrl: './formapago-form.page.html',
  styleUrls: ['./formapago-form.page.scss'],
})
export class FormapagoFormPage {

  constructor( private modalCtrl: ModalController,
               private proveedorService: ProveedorService,
               private ui: UiSereviceService) { }

  formaPago: FormaPago = {};
 
  async enviar(){
    const fp = await this.proveedorService.setFormaPago(this.formaPago);
    if (!fp.ok){
      this.ui.alertError(fp.error);
      return;
    }
    this.modalCtrl.dismiss(true);
  }

}
