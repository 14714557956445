import { Component, Input, OnInit } from '@angular/core';
import { Banco } from '../../../model/banco.model';
import { Sociedad } from '../../../model/sociedad.model';
import { ModalController } from '@ionic/angular';
import { SociedadSelectComponent } from '../../select/sociedad-select/sociedad-select.component';

@Component({
  selector: 'app-banco-form',
  templateUrl: './banco-form.page.html',
  styleUrls: ['./banco-form.page.scss'],
})
export class BancoFormPage implements OnInit {

  @Input() banco: Banco = {};
  edit: boolean;
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
  }

  async sociedadSelect(){
    const modal = await this.modalCtrl.create({
      component: SociedadSelectComponent,
      cssClass: 'cultivoSelect-modal'
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data){
      this.banco.sociedad = data;
    }
  }

  actualiza(){
    this.modalCtrl.dismiss(this.banco);
  }

  enviar(){
    this.modalCtrl.dismiss(this.banco);
  }

}
