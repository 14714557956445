import { Component, Input, OnInit } from '@angular/core';
import { FincaService } from '../../services/finca.service';
import { ModalController } from '@ionic/angular';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { Finca } from '../../../model/finca.model';

@Component({
  selector: 'app-finca-form',
  templateUrl: './finca-form.page.html',
  styleUrls: ['./finca-form.page.scss'],
})
export class FincaFormPage implements OnInit {

  @Input()finca: Finca = {};

  constructor( private fincaService: FincaService,
               private modalCtrl: ModalController,
               private ui: UiSereviceService) { }

  ngOnInit() {
  }

  async enviar(){
    let finca;
    if (!this.finca._id){
      finca = await this.fincaService.setFinca(this.finca);
    } else {
      finca = await this.fincaService.updateFinca(this.finca);
    }
    if (!finca.ok){
      this.ui.alertError(finca.error);
      return;
    }
    this.modalCtrl.dismiss(true);
  }

}
