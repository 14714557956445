import { Component, Input, OnInit } from '@angular/core';
import { Cultivo } from '../../../model/cultivo.model';
import { ModalController } from '@ionic/angular';
import { CultivoService } from '../../services/cultivo.service';
import { UiSereviceService } from '../../services/ui-serevice.service';

@Component({
  selector: 'app-explotacion-form',
  templateUrl: './explotacion-form.page.html',
  styleUrls: ['./explotacion-form.page.scss'],
})
export class ExplotacionFormPage implements OnInit {

  @Input()cultivo: Cultivo = {};
  constructor( private modalCtrl: ModalController,
               private cultivoService: CultivoService,
               private ui: UiSereviceService) { }

  ngOnInit() {
  }

  async  enviar(){
    let cult;
    if (!this.cultivo._id) {
      cult = await this.cultivoService.setCultivo(this.cultivo);
    } else {
      cult = await this.cultivoService.updateCultivo(this.cultivo);
    }
    if (!cult.ok){
      this.ui.alertError(cult.error);
      return;
    }
    this.modalCtrl.dismiss(true);
  }

}
