import { Component, Input, OnInit } from '@angular/core';
import { GastoTipo } from '../../../model/gastos.model';
import { IngresoTipo } from '../../../model/ingresosTipo.model';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-gasto-ingreso',
  templateUrl: './gasto-ingreso.page.html',
  styleUrls: ['./gasto-ingreso.page.scss'],
})
export class GastoIngresoPage implements OnInit {

  @Input()data: any = {};
  @Input() gasto: GastoTipo = {};
  @Input() ingreso: IngresoTipo = {};
  @Input() tipo: string = 'gasto';
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    console.log(this.data);
    switch(this.tipo){
      case 'ingreso':
        this.ingreso = this.data;
        break;
      case 'gasto':
        this.gasto = this.data;
        break;
    }

  }

  enviar(){
    if (this.tipo !== 'ingreso'){
      this.modalCtrl.dismiss({gasto: this.gasto});
    } else {
      this.modalCtrl.dismiss({ingreso: this.ingreso});
    }
  }

}
