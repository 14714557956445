import { Component, Input } from '@angular/core';
import { Sociedad } from '../../../model/sociedad.model';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { SociedadesService } from '../../services/sociedades.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-sociedad-form',
  templateUrl: './sociedad-form.page.html',
  styleUrls: ['./sociedad-form.page.scss'],
})
export class SociedadFormPage {

  constructor(private ui: UiSereviceService,
              private sociedadService: SociedadesService,
              private modalCtrl: ModalController
  ) { }

  @Input() sociedad: Sociedad = {};
  imagenes: any[] = [];
  imgTemp: string[] = [];
  files: File[] = [];
  processing: boolean;
  uploadImage: string;



  async presentActionSheet(fileLoader: any) {
    fileLoader.click();
    const that = this;
    fileLoader.onchange = () => {
      const file = fileLoader.files[0];
      this.files.push(file);
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        that.processing = true;

      }, false);
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.sociedad.logo = String(reader.result);
        };
      }
    };
  }




  async enviar() {
    let soc: any;

    if (!this.sociedad._id) {
      soc = await this.sociedadService.setSociedad(this.sociedad);
    } else {
      soc = await this.sociedadService.updateSociedad(this.sociedad);
    }
    if (!soc.ok) {
      this.ui.alertError(soc.error);
      return;
    }
    this.modalCtrl.dismiss(soc.ok);

  }
}
