import { Component, Input } from '@angular/core';
import { Proveedor } from '../../../model/proveedor.model';
import { ModalController } from '@ionic/angular';
import { FormaPago } from '../../../model/formaPago.model';
import { ProveedorService } from '../../services/proveedor.service';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { FormapagoFormPage } from '../formapago-form/formapago-form.page';

@Component({
  selector: 'app-proveedor-form',
  templateUrl: './proveedor-form.page.html',
  styleUrls: ['./proveedor-form.page.scss'],
})
export class ProveedorFormPage  {

  @Input()proveedor: Proveedor = {};
  formasPago: FormaPago[] = [];
  constructor( private modalCtrl: ModalController,
               private proveedorService: ProveedorService,
               private ui: UiSereviceService) { }

  ionViewWillEnter() {
    this.cargarFormaPago();
  }

  async cargarFormaPago(){
    const fp = await this.proveedorService.getFormasPago();
    if (!fp.ok){
      this.ui.alertError(fp.error);
      return;
    }
    this.formasPago = fp.formasPago;
  }



  async newFormaPago(){
    const modal = await this.modalCtrl.create({
      component: FormapagoFormPage,
      cssClass: 'formaPago-modal'
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data){
      await this.cargarFormaPago();
    }
  }

  async enviar(){
    let pv;
    if (this.proveedor._id){
      pv = await this.proveedorService.updateProveedor(this.proveedor);
    } else {
      pv = await this.proveedorService.setProveedor(this.proveedor);
    }
    if (!pv.ok){
      this.ui.alertError(pv.error);
      return;
    }
    this.modalCtrl.dismiss(true);
  }
}
