import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { UsuarioResp, Usuario } from '../../model/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor( private dataService: DataService) { }

  getUsuarios(){
    return new Promise<UsuarioResp>(resolve => {
      this.dataService.getData('usuarios').then((resp: UsuarioResp) => {
        resolve(resp);
      });
    });
  }
  setUsuarios(usuario: Usuario){
    return new Promise<UsuarioResp>(resolve => {
      this.dataService.setData('usuarios', usuario).then((resp: UsuarioResp) => {
        resolve(resp);
      });
    });
  }
  updateUsuarios(usuario: Usuario){
    return new Promise<UsuarioResp>(resolve => {
      this.dataService.updateData(`usuarios/${usuario._id}`, usuario).then((resp: UsuarioResp) => {
        resolve(resp);
      });
    });
  }
  delUsuarios(usuarioId: string){
    return new Promise<UsuarioResp>(resolve => {
      this.dataService.delData(`usuarios/${usuarioId}`).then((resp: UsuarioResp) => {
        resolve(resp);
      });
    });
  }
}
